import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
  IonPage,
  IonIcon,
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonLabel,
} from '@ionic/vue';
import { listOutline, carSportOutline, personOutline } from 'ionicons/icons';
import { useStore } from '@/store';
import router from '@/router';
import { isWebx } from '@/utils/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'TabLayout',
  setup(__props) {

const web = isWebx();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          (
          !(
            _unref(useStore)().activeBooking.isActive &&
            _unref(router).currentRoute.value.name === 'newBooking'
          )
        )
            ? (_openBlock(), _createBlock(_unref(IonTabBar), {
                key: 0,
                slot: _unref(web) ? 'top' : 'bottom',
                style: {"border-bottom":"0"},
                color: _unref(web) ? 'primary' : undefined
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonTabButton), {
                    tab: "home",
                    href: "/home"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), { icon: _unref(listOutline) }, null, 8, ["icon"]),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('base.currentBookings')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonTabButton), {
                    tab: "newBooking",
                    href: "/newBooking"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), { icon: _unref(carSportOutline) }, null, 8, ["icon"]),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('base.newBooking')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonTabButton), {
                    tab: "profile",
                    href: "/profile"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), { icon: _unref(personOutline) }, null, 8, ["icon"]),
                      _createVNode(_unref(IonLabel), null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('base.profile')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["slot", "color"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})