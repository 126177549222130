import { Config } from '@/config/types';
import { awsConfig } from '@/config/aws';
import { SectionNames } from '@/config/constants';

export const brandConfig: Config = {
  env: process.env.NODE_ENV || 'development',
  app: {
    logLevel: process.env.VUE_APP_LOG_LEVEL || 'silly',
    supportToEmail: 'info@scschauffeur.co.uk',
    companyTelephoneNumber: '0203 725 4207',
    avatarSize: 128,
    documentSize: 1024,
    ownerId: 'SCS',
    companyName: 'SCS: Chauffeur Services',
    defaultCountryCode: 'GB',
    appDetailsRefreshMins: 30,
    maxContactDropDown: 10,
    addressSearch: {
      searchStringMinLength: 1,
      searchResultCount: 5,
      language: 'en-gb',
      gps: {
        radiusSearch: 50,
        timeout: 3000,
        maximumAge: 300000,
      },
    },
    addressBook: {
      maxShown: 10,
      initialTypes: ['contact', 'recent', 'account'],
    },
    mapView: {
      hereMapsKey: process.env.VUE_APP_HERE_MAPS_KEY || '',
      startPointSvg:
        '<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C64 14.3 49.7 0 32 0S0 14.3 0 32V64 368 480c0 17.7 14.3 32 32 32s32-14.3 32-32V352l64.3-16.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L64 48V32z"/></svg>',
      viaPointSvg:
        '<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24V64 350.5 400v88c0 13.3 10.7 24 24 24s24-10.7 24-24V388l80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V66.1c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52V24zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8V334.7l-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5v-237z"/></svg>',
      endPointSvg:
        '<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M32 0C49.7 0 64 14.3 64 32V48l69-17.2c38.1-9.5 78.3-5.1 113.5 12.5c46.3 23.2 100.8 23.2 147.1 0l9.6-4.8C423.8 28.1 448 43.1 448 66.1V345.8c0 13.3-8.3 25.3-20.8 30l-34.7 13c-46.2 17.3-97.6 14.6-141.7-7.4c-37.9-19-81.3-23.7-122.5-13.4L64 384v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V400 334 64 32C0 14.3 14.3 0 32 0zM64 187.1l64-13.9v65.5L64 252.6V318l48.8-12.2c5.1-1.3 10.1-2.4 15.2-3.3V238.7l38.9-8.4c8.3-1.8 16.7-2.5 25.1-2.1l0-64c13.6 .4 27.2 2.6 40.4 6.4l23.6 6.9v66.7l-41.7-12.3c-7.3-2.1-14.8-3.4-22.3-3.8v71.4c21.8 1.9 43.3 6.7 64 14.4V244.2l22.7 6.7c13.5 4 27.3 6.4 41.3 7.4V194c-7.8-.8-15.6-2.3-23.2-4.5l-40.8-12v-62c-13-3.8-25.8-8.8-38.2-15c-8.2-4.1-16.9-7-25.8-8.8v72.4c-13-.4-26 .8-38.7 3.6L128 173.2V98L64 114v73.1zM320 335.7c16.8 1.5 33.9-.7 50-6.8l14-5.2V251.9l-7.9 1.8c-18.4 4.3-37.3 5.7-56.1 4.5v77.4zm64-149.4V115.4c-20.9 6.1-42.4 9.1-64 9.1V194c13.9 1.4 28 .5 41.7-2.6l22.3-5.2z"/></svg>',
      pointSvgOffset: {
        x: 5,
        y: 30,
      },
      trackingSvg:
        '<svg width="50" height="50" viewBox="-41.2325 10.6538 588.3017 588.3017"><path d="M 458.521 140.887 C 463.421 128.987 460.621 115.287 451.521 106.187 C 442.421 97.087 428.721 94.287 416.821 99.187 L 64.82 243.187 C 50.62 248.987 42.62 263.987 45.52 278.987 C 48.42 293.987 61.62 304.787 76.92 304.787 L 252.92 304.787 L 252.92 480.787 C 252.92 496.087 263.72 509.187 278.72 512.187 C 293.72 515.187 308.72 507.087 314.52 492.887 L 458.521 140.887 Z" style="transform-origin: 252.918px 304.805px;" transform="matrix(0.707107007504, -0.707107007504, 0.707107007504, 0.707107007504, 4.06849e-7, -0.000016299803)"></path></svg>',
      trackingSvgOffset: {
        x: 25,
        y: 25,
      },
      locationSvg:
        '<svg width="30" height="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>',
      locationSvgOffset: {
        x: 15,
        y: 30,
      },
      animateFocus: false,
      defaultLocation: { lat: 51.506101, lng: -0.165164 },
      mapRefreshSecs: 15,
      routeLineStyle: {
        strokeColor: 'blue',
        lineWidth: 5,
      },
      trackingLineStyle: {
        strokeColor: 'red',
        lineWidth: 10,
        lineDash: [0, 2],
      },
    },
    bookingForm: {
      bookingTimeMins: 1,
      showFavouriteJourneys: true,
      showRecentJourneys: true,
      autoFavAddressSearch: true,
      sectionOpenStateDefaults: {
        when: 'default',
        jobFlags: 'default',
        where: 'default',
        goods: 'default',
        payment: 'default',
        tariffSlideshow: 'always',
        references: 'default',
        contact: 'default',
        jobExtras: 'default',
      },
      webxSeparateSections: [SectionNames.MAP, SectionNames.TARIFF_SLIDESHOW],
      showTelOnViewOnlyTariff: true,
    },
    webxEmbed: {
      useSecondaryHeaderColour: false,
    },
  },
  devLogControl: {
    cloudwatch: false,
  },
  services: {
    authServiceHosts: JSON.parse(process.env.VUE_APP_AUTH_SERVICE_REST || '[]'),
    integraLinkHosts: JSON.parse(process.env.VUE_APP_INTEGRA_LINK || '[]'),
    freedomQlHosts: JSON.parse(process.env.VUE_APP_FREEDOM_QL || '[]'),
    webBookingUrl: process.env.VUE_APP_WEB_BOOKING_URL || '',
    fdmBrowserUrl: process.env.VUE_APP_FDM_BROWSER_URL || '',
    adflexBaseUrl: JSON.parse(process.env.VUE_APP_ADFLEX_BASE_URL || '[]'),
    adflexScript: process.env.VUE_APP_ADFLEX_SCRIPT || '',
    apiMsTimeout: parseInt(process.env.VUE_APP_API_TIMEOUT || '30000'),
    debounceMs: 500,
    aws: {
      awsConfig,
    },
    cloudWatch: {
      region: 'eu-west-2',
      logGroupName: '/freedom/gox-SCS/app/v1',
      intervalSecs: 300,
      batchSize: 50,
    },
    loggerProxy: {
      url: process.env.VUE_APP_LOGGER_URL || '',
      clientId: process.env.VUE_APP_LOGGER_CLIENTID || '',
      clientSecret: process.env.VUE_APP_LOGGER_CLIENTSECRET || '',
    },
  },
};
