import router from '@/router';
import { t } from '@/utils/i18n';
import { alertController } from '@ionic/vue';
import { signOut } from './auth';
import { App } from '@capacitor/app';
import { useStore } from '@/store';
import { Contact } from '@/api/freedomQl/__generated__/graphql';
import config from '@/config';
import { integraUserToContact } from '../utils/helpers';

const okAlertWithMessage = async (message: string) => {
  const alert = await alertController.create({
    message,
    buttons: [
      {
        text: t('base.ok'),
        role: 'confirm',
      },
    ],
  });

  await alert.present();
};

export const displayAdflexNavigationAlert = async (backPageName: string) => {
  const alert = await alertController.create({
    header: t('base.areYouSure'),
    message: t('base.adflexNavWarning'),
    buttons: [
      {
        text: t('base.cancel'),
        role: 'cancel',
      },
      {
        text: t('base.ok'),
        role: 'destructive',
        handler: async () => {
          await router.replace({ name: backPageName });
        },
      },
    ],
  });

  await alert.present();
};

export const displaySignOutAlert = async () => {
  const alert = await alertController.create({
    header: t('base.areYouSure'),
    message: t('base.signOutConfirmation'),
    buttons: [
      {
        text: t('base.cancel'),
        role: 'cancel',
      },
      {
        text: t('base.ok'),
        role: 'destructive',
        handler: async () => {
          await signOut();
        },
      },
    ],
  });

  await alert.present();
};

export const displayExitAppAlert = async () => {
  const alert = await alertController.create({
    header: t('base.areYouSure'),
    message: t('base.exitAppConfirmation'),
    buttons: [
      {
        text: t('base.cancel'),
        role: 'cancel',
      },
      {
        text: t('base.ok'),
        role: 'destructive',
        handler: async () => {
          App.exitApp();
        },
      },
    ],
  });

  await alert.present();
};

export const displayDeleteAlert = async (func: () => Promise<void>) => {
  const alert = await alertController.create({
    header: t('base.areYouSure'),
    buttons: [
      {
        text: t('base.cancel'),
        role: 'cancel',
      },
      {
        text: t('base.ok'),
        role: 'destructive',
        handler: async () => {
          await func();
        },
      },
    ],
  });

  await alert.present();
};

export const displayFlightAirportAlert = async (
  stopCode: string,
  flightCode: string,
  isArrival: boolean
) => {
  const alert = await alertController.create({
    header: t('base.flightSearch.flightWarning'),
    subHeader: `${t('base.flightSearch.selectedAirport', {
      airportCode: stopCode,
    })}, ${t('base.flightSearch.flightAirport', {
      airportCode: flightCode,
    })}`,
    message: t(
      isArrival
        ? 'base.flightSearch.airportArriveWarningMessage'
        : 'base.flightSearch.airportDepartWarningMessage'
    ),
    buttons: ['OK'],
  });

  await alert.present();
};

export const displayFlightTerminalAlert = async (
  stopCode: string,
  terminal: string,
  isArrival: boolean
) => {
  const alert = await alertController.create({
    header: t('base.flightSearch.flightWarning'),
    subHeader: `${t('base.flightSearch.selectedAirport', {
      airportCode: stopCode,
    })}, ${t('base.flightSearch.flightTerminal', {
      terminal,
    })}`,
    message: t(
      isArrival
        ? 'base.flightSearch.terminalArriveWarningMessage'
        : 'base.flightSearch.terminalDepartWarningMessage'
    ),
    buttons: ['OK'],
  });

  await alert.present();
};

export const displayBookingFlightTimeAlert = async (dateTime: string) => {
  const alert = await alertController.create({
    header: t('base.flightSearch.matchFlightTimeHeader'),
    message: t('base.flightSearch.matchFlightTimeMessage'),
    buttons: [
      {
        text: t('base.cancel'),
        role: 'cancel',
      },
      {
        text: t('base.ok'),
        role: 'confirm',
        handler: () => {
          useStore().activeBooking.setValues = [
            {
              dataName: 'requiredDateTime',
              value: dateTime,
            },
            {
              dataName: 'asap',
              value: false,
            },
          ];
        },
      },
    ],
  });

  await alert.present();
};

export const displayMasqueradeFavBookingAlert = async (
  contact: Contact,
  continueFunction: () => Promise<void>
) => {
  const alert = await alertController.create({
    header: t('base.areYouSure'),
    message: t('base.bookAsWarning', {
      contactName: contact.contactName,
    }),
    buttons: [
      {
        text: t('base.cancel'),
        role: 'cancel',
      },
      {
        text: t('base.ok'),
        role: 'confirm',
        handler: async () => {
          useStore().jobContact = contact;
          await continueFunction();
        },
      },
    ],
  });

  await alert.present();
};

export const displayMasqueradeActionBookingAlert = async (contact: Contact) => {
  const store = useStore();
  const alert = await alertController.create({
    header: t('base.areYouSure'),
    message: t('base.bookAsWarning', {
      contactName: contact.contactName,
    }),
    buttons: [
      {
        text: t('base.cancel'),
        role: 'cancel',
        handler: async () => {
          useStore().jobContact = integraUserToContact(
            store.integraProfile.user
          );
          await router.replace({ name: 'home' });
        },
      },
      {
        text: t('base.ok'),
        role: 'confirm',
      },
    ],
  });

  await alert.present();
};

export const displayNoAddJobsAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.addJobsMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};

export const displayNoEditJobsAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.editJobsMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};

export const displayNoCancelJobsAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.cancelJobsMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};

export const displayNoAddCardsAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.addCardsMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};

export const displayNoEditContactsAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.editContactsMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};

export const displayNoAddAddressesAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.addAddressesMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};

export const displayNoEditAddressesAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.editAddressesMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};

export const displayNoCanRequestAccessAlert = async () => {
  await okAlertWithMessage(
    t('base.webAccess.canRequestMessage', {
      companyTelephoneNumber: config.app.companyTelephoneNumber,
    })
  );
};
