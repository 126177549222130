import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabLayout from '@/components/TabLayout.vue';

import 'vue-router';
export {};

declare module 'vue-router' {
  interface RouteMeta {
    isPublic?: boolean;
  }
}

import { isSignedIn } from '@/services/auth';
import { useStore } from '@/store';
import logger from '@/services/logger';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/',
    component: TabLayout,
    children: [
      {
        path: '',
        redirect: '/home',
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/reports/ReportsPage.vue'),
      },
      {
        path: '/newBooking',
        name: 'newBooking',
        component: () => import('@/views/booking/NewBookingPage.vue'),
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/profile/ProfilePage.vue'),
      },
    ],
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('@/views/auth/SignInPage.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/sso',
    name: 'sso',
    component: () => import('@/views/auth/SSOPage.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/auth/SignUpPage.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/verifyCode',
    name: 'verifyCode',
    component: () => import('@/views/auth/VerifyCodePage.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('@/views/auth/ForgotPasswordPage.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/setPassword/:initialEmail',
    name: 'setPassword',
    props: true,
    component: () => import('@/views/auth/SetPasswordPage.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutPage.vue'),
    meta: { isPublic: true },
  },
  {
    path: '/selectProfile',
    name: 'selectProfile',
    component: () => import('@/views/auth/SelectProfilePage.vue'),
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/auth/ChangePasswordPage.vue'),
  },
  {
    path: '/devSettings',
    name: 'devSettings',
    component: () => import('@/views/dev/DevSettingsPage.vue'),
  },
  {
    path: '/editConfig',
    name: 'editConfig',
    component: () => import('@/views/dev/EditConfigPage.vue'),
  },
  {
    path: '/currentState',
    name: 'currentState',
    component: () => import('@/views/dev/CurrentStatePage.vue'),
  },
  {
    path: '/viewLogs',
    name: 'viewLogs',
    component: () => import('@/views/dev/ViewLogsPage.vue'),
  },
  {
    path: '/viewBooking/:reference&jobLabel=:jobLabel',
    name: 'viewBooking',
    props: ({ params }) => ({
      reference: Number.parseInt(params.reference as string) || 0,
      jobLabel: params.jobLabel ?? '',
    }),
    component: () => import('@/views/booking/ViewBookingPage.vue'),
  },
  {
    path: '/viewMap/:jobRef&jobLabel=:jobLabel/:focusStop',
    name: 'viewMap',
    props: ({ params }) => ({
      jobRef: Number.parseInt(params.jobRef as string) || 0,
      jobLabel: params.jobLabel ?? '',
      focusStop: Number.parseInt(params.focusStop as string) || 0,
    }),
    component: () => import('@/views/ViewMapPage.vue'),
  },
  {
    path: '/bookingPayment',
    name: 'bookingPayment',
    component: () => import('@/views/booking/BookingPaymentPage.vue'),
  },
  {
    path: '/addCard',
    name: 'addCard',
    component: () => import('@/views/profile/AddCardPage.vue'),
  },
  {
    path: '/editProfile',
    name: 'editProfile',
    component: () => import('@/views/profile/EditProfilePage.vue'),
  },
  {
    path: '/viewAddress',
    name: 'viewAddress',
    component: () => import('@/views/profile/ViewAddressPage.vue'),
  },
  {
    path: '/mapSearch',
    name: 'mapSearch',
    component: () => import('@/views/booking/MapSearchPage.vue'),
  },
  {
    path: '/editBooking/:reference',
    name: 'editBooking',
    props: ({ params }) => ({
      reference: Number.parseInt(params.reference as string) || 0,
    }),
    component: () => import('@/views/booking/EditBookingPage.vue'),
  },
  {
    path: '/flightSearch/:stopIndex',
    name: 'flightSearch',
    props: ({ params }) => ({
      stopIndex: Number.parseInt(params.stopIndex as string) || 0,
    }),
    component: () => import('@/views/booking/FlightSearchPage.vue'),
  },
  {
    path: '/contactLink',
    name: 'contactLink',
    component: () => import('@/views/profile/ContactLinkPage.vue'),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/profile/SettingsPage.vue'),
  },
  {
    path: '/mfaSettings',
    name: 'mfaSettings',
    component: () => import('@/views/auth/MFASettingsPage.vue'),
  },
  {
    path: '/totpSetup',
    name: 'totpSetup',
    component: () => import('@/views/auth/TOTPSetupPage.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  const toFrom = { to: to.name, from: from.name };
  const store = useStore();
  const cognitoSignIn = await isSignedIn();

  if (
    cognitoSignIn &&
    !to.meta.isPublic &&
    !store.integraProfile?.userSession
  ) {
    // User is signed into cognito and accessing private page on app reopen
    await store.$hydrate();
    logger.debug('hydratingStore', toFrom);
  }

  const integraSignIn = store.isSignedIntoIntegra;
  const isFullySignedIn = cognitoSignIn && integraSignIn;
  const toSelectProfile = to.name === 'selectProfile';
  if (
    (!isFullySignedIn && !to.meta.isPublic && !toSelectProfile) ||
    (toSelectProfile && !from.name)
  ) {
    // User is not fully signed in and accessing private page (other than selectProfile page)
    // OR user reopens app on the selectProfile page
    await store.$hydrate();
    logger.debug('navigatingToSignIn', {
      ...toFrom,
      cognitoSignIn,
      integraSignIn,
    });
    return { name: 'signin' };
  }

  logger.debug('navigating', {
    ...toFrom,
    cognitoSignIn,
    integraSignIn,
  });

  if (
    isFullySignedIn &&
    to.name === 'home' &&
    (from.name === 'selectProfile' || !from.name)
  ) {
    store.initialNav = true;
  }
});

export default router;
