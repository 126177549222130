import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }

import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButtons,
  IonBackButton,
  IonImg,
  IonMenuButton,
  IonButton,
  IonIcon,
  IonGrid,
  IonFooter,
} from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import { ref, watch } from 'vue';
import { isWebx, inIframe } from '@/utils/helpers';
import config from '@/config';


export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLayout',
  props: {
  title: {
    type: String,
    default: '',
  },
  defaultBackLinkUrl: {
    type: String,
    default: '/',
  },
  showBackButton: {
    type: Boolean,
    default: true,
  },
  showMenu: {
    type: Boolean,
    default: true,
  },
  isError: {
    type: Boolean,
    default: false,
  },
  backFunction: {
    type: Function as any,
    required: false,
  },
  disableButtons: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  fixedGrid: {
    type: Boolean,
    default: true,
  },
  useLoadingTimeout: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const showLoading = ref(props.isLoading);

watch(
  () => props.isLoading,
  (loading) => {
    showLoading.value = loading;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), { id: "main-content" }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), {
            color: 
          _unref(inIframe)() && _unref(config).app.webxEmbed.useSecondaryHeaderColour
            ? 'secondary'
            : 'primary'
        
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonButtons), { slot: "start" }, {
                default: _withCtx(() => [
                  (__props.backFunction)
                    ? (_openBlock(), _createBlock(_unref(IonButton), {
                        key: 0,
                        onClick: __props.backFunction,
                        color: "light",
                        disabled: __props.disableButtons || showLoading.value
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonIcon), {
                            icon: _unref(chevronBackOutline),
                            style: {"margin-left":"-10px"}
                          }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('base.back')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "disabled"]))
                    : (__props.showBackButton)
                      ? (_openBlock(), _createBlock(_unref(IonBackButton), {
                          key: 1,
                          "default-href": __props.defaultBackLinkUrl,
                          text: _ctx.$t('base.back'),
                          disabled: __props.disableButtons || showLoading.value
                        }, null, 8, ["default-href", "text", "disabled"]))
                      : (_openBlock(), _createBlock(_unref(IonButton), {
                          key: 2,
                          "router-link": "/about",
                          expand: "block",
                          fill: "clear",
                          disabled: __props.disableButtons || showLoading.value
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonImg), {
                              class: "company-favicon ion-margin-end",
                              src: "../assets/favicon.png",
                              alt: "Favicon"
                            })
                          ]),
                          _: 1
                        }, 8, ["disabled"]))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTitle), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(__props.title), 1)
                ]),
                _: 1
              }),
              (__props.showMenu)
                ? (_openBlock(), _createBlock(_unref(IonButtons), {
                    key: 0,
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonMenuButton), {
                        disabled: __props.disableButtons || showLoading.value
                      }, null, 8, ["disabled"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["color"])
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          (showLoading.value)
            ? (_openBlock(), _createBlock(LoadingSpinner, {
                key: 0,
                "use-loading-timeout": __props.useLoadingTimeout,
                onLoadingTimeout: _cache[0] || (_cache[0] = ($event: any) => (showLoading.value = false))
              }, null, 8, ["use-loading-timeout"]))
            : _createCommentVNode("", true),
          (__props.isError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('base.errorOccured')), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(IonGrid), { fixed: __props.fixedGrid }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "default", { class: "tab-content" })
                  ]),
                  _: 3
                }, 8, ["fixed"])
              ]))
        ]),
        _: 3
      }),
      (_unref(isWebx)())
        ? (_openBlock(), _createBlock(_unref(IonFooter), {
            key: 0,
            class: "ion-text-center"
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("p", { style: {"font-size":"11px"} }, [
                _createTextVNode(" Copyright © Nelson West Ltd 2024 Sold under licence by "),
                _createElementVNode("strong", null, [
                  _createElementVNode("a", { href: "http://www.catalina-software.co.uk/" }, " Catalina Software Ltd ")
                ])
              ], -1)
            ])),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})