import {
  AddressWithType,
  Contact,
  WebAccess,
} from '@/api/freedomQl/__generated__/graphql';
import {
  BookingDelay,
  BookingFlow,
  BookingQuote,
  BookingTemplate,
  IntegraProfile,
  TariffErrorResponse,
  WebTariff,
  AllowedMop,
  GoodsType,
  FlightResponse,
  ErrorResponse,
} from '@/api/integraLink/types';
import { getDefaultDynamicConfig } from '@/config';
import { DynamicConfig } from '@/config/types';
import { Position } from '@capacitor/geolocation';

type BasicToken = {
  token: string;
};

type JwtToken = {
  jwt: string;
  expiry: number;
};

type Parameter = {
  code: string;
  setting: string;
};

type SignInDetails = {
  remember: boolean;
  email?: string;
  confirmSignInPending: boolean;
};

type MFADetails = {
  wrongPassCount: number;
  pendingSMS: boolean;
  pendingTOTP: boolean;
  pendingLegacy: boolean;
  email: string;
};

type ActiveBooking = {
  isActive: boolean;
  isEdit: boolean;
  fromAddress?: AddressWithType;
  toAddress?: AddressWithType;
  bookingForm: any;
  bookingTemplate?: BookingTemplate;
  earliestPickup?: string;
  tariffs?: WebTariff[];
  goodsTypes?: GoodsType[];
  mops?: AllowedMop[];
  delays: (TariffErrorResponse | BookingDelay)[];
  quotes: BookingQuote[];
  quoteError?: ErrorResponse;
  searchedAddressId?: string;
  journeyDistance?: number;
  journeyDuration?: number;
  selectedFlight?: { stopIndex: number; flight: FlightResponse };
  setValues?: { dataName: string; value: any }[];
  jobPassenger?: Contact;
  preSelectedTemplate?: BookingTemplate;
  ready: boolean;
  copyBookingRef?: number;
};

export type MasqueradeDetails = {
  count: number;
  useSearch: boolean;
  contacts: Contact[];
};

export type AppDetails = {
  updated?: string;
  bookingFlow?: BookingFlow;
  webAccess?: WebAccess;
  bookFor?: MasqueradeDetails;
  masqueradeAndMaintain?: MasqueradeDetails;
};

export type State = {
  signInDetails: SignInDetails;
  mfaDetails: MFADetails;
  integraLinkJwt: string;
  dynamicConfig: DynamicConfig;
  appDetails: AppDetails;
  integraProfile: IntegraProfile;
  activeBooking: ActiveBooking;
  authTokens: {
    id?: JwtToken;
    access?: JwtToken;
    refresh?: BasicToken;
  };
  appData: {
    parameters: Parameter[];
  };
  currentAddress: AddressWithType;
  profileContact: Contact;
  jobContact: Contact;
  initialNav: boolean;
  location?: Position;
  cookieConsent: boolean;
};

export const getDefaultAuthTokens = () => {
  return {
    id: undefined,
    access: undefined,
    refresh: undefined,
  };
};

export const getDefaultActiveBooking = () => {
  return {
    isActive: false,
    isEdit: false,
    fromAddress: undefined,
    toAddress: undefined,
    bookingForm: undefined,
    bookingTemplate: undefined,
    earliestPickup: undefined,
    tariffs: undefined,
    goodsTypes: undefined,
    mops: undefined,
    delays: [] as BookingDelay[],
    quotes: [] as BookingQuote[],
    quoteError: undefined,
    searchedAddressId: undefined,
    journeyDistance: undefined,
    journeyDuration: undefined,
    selectedFlight: undefined,
    setValues: undefined,
    ready: false,
  };
};

export const getDefaultAppDetails = () => {
  return {
    updated: undefined,
    bookingFlow: undefined,
    bookFor: {
      count: 0,
      useSearch: false,
      contacts: [],
    },
    masqueradeAndMaintain: {
      count: 0,
      useSearch: false,
      contacts: [],
    },
  };
};

export const getDefaultSignInDetails = () => ({
  email: '',
  remember: false,
  confirmSignInPending: false,
});

export const getDefaultMFADetails = () => ({
  wrongPassCount: 0,
  pendingSMS: false,
  pendingTOTP: false,
  pendingLegacy: false,
  email: '',
});

export const getDefaultState = (): State => {
  return {
    signInDetails: getDefaultSignInDetails(),
    mfaDetails: getDefaultMFADetails(),
    integraLinkJwt: '',
    dynamicConfig: getDefaultDynamicConfig(),
    appDetails: getDefaultAppDetails(),
    integraProfile: {} as IntegraProfile,
    activeBooking: getDefaultActiveBooking(),
    authTokens: getDefaultAuthTokens(),
    appData: {
      parameters: [] as Parameter[],
    },
    currentAddress: {} as AddressWithType,
    profileContact: {} as Contact,
    jobContact: {} as Contact,
    initialNav: false,
    location: undefined,
    cookieConsent: false,
  };
};
