import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { signOut } from '@/services/auth';
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonMenuToggle,
  IonIcon,
} from '@ionic/vue';
import { peopleOutline, cogOutline, exitOutline } from 'ionicons/icons';


export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalMenu',
  setup(__props) {

const doSignOut = async () => {
  await signOut();
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonMenu), {
    "content-id": "main-content",
    side: "end",
    "swipe-gesture": false
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), null, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTitle), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('base.menu')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_unref(IonMenuToggle), null, {
              default: _withCtx(() => [
                _createVNode(_unref(IonButton), {
                  "router-link": "/selectProfile",
                  expand: "block",
                  fill: "clear"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(peopleOutline),
                      slot: "start",
                      class: "icon-med"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('auth.switchProfile')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_unref(IonButton), {
                  "router-link": "/settings",
                  expand: "block",
                  fill: "clear"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(cogOutline),
                      slot: "start",
                      class: "icon-med"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('base.settings')), 1)
                  ]),
                  _: 1
                }),
                _cache[0] || (_cache[0] = _createElementVNode("hr", { style: {"background-color":"var(--ion-color-primary)"} }, null, -1)),
                _createVNode(_unref(IonButton), {
                  onClick: doSignOut,
                  expand: "block",
                  fill: "clear",
                  color: "danger"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(exitOutline),
                      slot: "start",
                      class: "icon-med"
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('auth.signOut')), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})