import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loader-container centered" }

import logger from '@/services/logger';
import { IonBackdrop } from '@ionic/vue';
import { onMounted, onUnmounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingSpinner',
  props: {
  useLoadingTimeout: {
    type: Boolean,
    default: false,
  },
},
  emits: ['loadingTimeout'],
  setup(__props, { emit: __emit }) {

const props = __props;

let loadingTimer: NodeJS.Timeout;

onMounted(() => {
  if (props.useLoadingTimeout) {
    loadingTimer = setTimeout(() => {
      logger.warn('Loading timeout');
      emit('loadingTimeout');
    }, 10000);
  }
});

onUnmounted(() => {
  if (props.useLoadingTimeout) {
    clearTimeout(loadingTimer);
  }
});

const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_unref(IonBackdrop), { visible: true }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('base.pleaseWait')), 1),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "loader" }, null, -1))
    ])
  ]))
}
}

})