import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cookie-popup" }
const _hoisted_2 = {
  href: "https://cdn.catalina-software.co.uk/PrivacyPolicy/FreedomGoX",
  target: "_blank"
}
const _hoisted_3 = { class: "web-buttons" }

import { IonButton, IonModal, IonText } from '@ionic/vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CookieConsentModal',
  props: {
  isOpen: {
    type: Boolean,
    required: true,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    "is-open": __props.isOpen,
    swipeToClose: "false",
    backdropDismiss: "false"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(IonText), null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('base.cookieConsent.message')) + " ", 1),
            _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$t('base.cookieConsent.privacyPolicy')), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(IonButton), {
            expand: "full",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('accept'))),
            class: "ion-margin"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('base.cookieConsent.accept')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["is-open"]))
}
}

})