import { Config, DynamicConfig } from '@/config/types';
import { brandConfig } from './brandConfig';
import versionConfig from '../../version-switch/versionConfig.json';

const version = versionConfig.version;
const config: Config = { ...brandConfig, app: { ...brandConfig.app, version } };

export const getDefaultDynamicConfig = (): DynamicConfig => {
  return {
    app: {
      logLevel: config.app.logLevel,
    },
    services: {
      integraLinkHosts: config.services.integraLinkHosts,
      freedomQlHosts: config.services.freedomQlHosts,
    },
  };
};

export default config;
