import { AddressWithType } from '../freedomQl/__generated__/graphql';

export type KeyValue = {
  [key: string]: any | undefined;
};

export type ErrorResponse = {
  error: {
    code: number;
    text: string;
  };
};

export type TariffErrorResponse = {
  error: {
    code: number;
    text: string;
  };
  tariff: string;
  obtainedDt: string;
};

export type SuccessResponse = {
  success: boolean;
};

export const isErrorResponse = (model: unknown): model is ErrorResponse => {
  const possibleError = model as ErrorResponse;
  return (
    possibleError.error?.code !== undefined &&
    possibleError.error?.text !== undefined
  );
};

export type Image = {
  mimetype: string;
  image: string;
};

export type AdflexSessionRequest = {
  currency: string;
  value: string;
  email?: string;
  mobile?: string;
};

export type AdflexSessionResponse = {
  sessionId: string;
  transactionGuid: string;
  transactionReference: string;
};

export type IntegraContactInfo = {
  contactRef?: number;
  name: string;
  telephone?: string;
  mobile: string;
};

export type IntegraSignInInfo = {
  email: string;
  name?: string;
  telephone?: string;
  mobile?: string;
  contactRef?: number;
  accountRef?: string;
  accountSignUpPIN?: number;
  accountSignUpCode?: string;
};

export type BookingFlow = {
  bookingTypes: BookingTemplate[];
};

export type BookingTemplate = {
  id: number;
  label: string;
  icon?: string;
  sections: BookingSection[];
  order: number;
};

export type BookingSection = {
  name: string;
  label: string;
  headerInputs?: string[];
  watcherInputs?: string[];
  icon?: string;
  inputs: BookingInput[] | undefined;
  order: number;
  openState?: OpenState;
};

export type OpenState = 'default' | 'open' | 'always' | 'never';

export type OpenStates = {
  when: OpenState;
  jobFlags: OpenState;
  where: OpenState;
  goods: OpenState;
  payment: OpenState;
  tariffSlideshow: OpenState;
  references: OpenState;
  contact: OpenState;
  jobExtras: OpenState;
};

export type BookingInput = {
  id?: number; // hydrated for flags + refs
  dataName: string;
  label: string;
  type: string;
  subType?: any;
  compulsory?: boolean;
  default?: any;
  min?: any;
  max?: any;
  params?: any;
  order: number;
  visible?: number; // 0 | 1
};

export type BookingParam = {
  reference: string;
  code: string;
  label: string;
  icon?: string;
};

export type JobDetails = {
  dt: string;
  waitAndReturn: boolean;
  stops: BookingStop[];
  goods: BookingGood[];
  refs: BookingRef[];
  flags: BookingFlag[];
  mop: string;
  card?: CardTokenInfo;
  cardReference?: number;
  despatchInstructions?: string;
  extras?: BookingExtra[];
};

export type CardTokenInfo = {
  transactionGuid: string;
  transactionReference: string;
  token: string;
};

export type Booking = {
  ref?: number;
  tariff: string;
} & JobDetails;

export type BookingStop = {
  addr: BookingAddress;
};

export type BookingGood = {
  type: number;
  description: string;
  info1?: string;
  info2?: string;
  info3?: string;
  unitWeight?: number;
  unitHeight?: number;
  unitWidth?: number;
  unitDepth?: number;
  unitValue?: number;
  qty: number;
  subQty: number;
};

export type BookingAddress = {
  name?: string;
  add1?: string;
  add2?: string;
  add3?: string;
  add4?: string;
  postcode: string;
  lat: number;
  long: number;
};

export type BookingRef = {
  id: number;
  valueId?: number;
  value?: string;
};

export type BookingFlag = {
  id: number;
  value: boolean;
};

export type BookingExtra = {
  code: string;
  value: any;
};

export type BookingDelayBody = {
  tariffs: string[];
  stops: BookingDelayLocation[];
  job?: JobDetails;
};

export type BookingDelayLocation = {
  postcode: string;
  lat: number;
  long: number;
};

export type BookingDelay = {
  tariff: string;
  delay: string;
  message: string;
  earliest: string;
  preventJob: boolean;
  obtainedDt: string;
};

export type BookingQuoteBody = {
  tariffs: string[];
  job: JobDetails;
};

export type BookingQuote =
  | {
      section: string;
      showPrice: boolean;
      tariff: string;
      pickupTime?: string;
      delay?: number;
      delayMessage?: string;
      delayEarliest?: string;
      tariffSort: number;
      mop: string;
      priceNet?: number;
      priceVat?: number;
      total?: number;
      description: string;
      currencyCode: string;
      warning?: string;
      note: string;
    }
  | undefined;

export type BookingQuoteResponse = {
  journeyTime?: number;
  distance?: number;
  quotes: BookingQuote[];
};

export type IntegraAuthToken = string;

export type Response = {
  jwt?: IntegraAuthToken;
};

export type NewBookingResponse = {
  ref: number;
  link: string;
};

export type EditBookingResponse = SuccessResponse;

export type SessionAuth = { sessID: number; sessCK: string };

export type LogOnResponse = {
  profile: IntegraProfile;
  error?: {
    code: string;
    message: string;
  };
};

export type WebTariff = {
  reference: number;
  tariff: string;
  label?: string;
  description?: string;
  sort_order: number;
  view_only?: number;
  warning_msg?: string;
  default_goods_type?: number;
  max_passenger: number;
  max_goods_qty: number;
  max_goods_depth: number;
  max_goods_width: number;
  max_goods_height: number;
  max_goods_weight: number;
  max_goods_cartons: number;
  cutoff_time?: string;
  url?: string;
  img_url?: string;
  app_img_url?: string;
  app_img_url_updated_dt?: string;
};

export type GoodsType = {
  id: number;
  description: string;
  code: string;
};

export type AllowedMop = {
  code: string;
  description: string;
};

export type IntegraUser = {
  contactRef: number;
  accountRef: string;
  bname: string;
  btel?: string;
  bmob: string;
  bfax?: string;
  btitle?: string;
  bemail: string;
  payment: {
    defaultMOP: string;
    allowedMOP: AllowedMop[];
  };
  callCentre: {
    cc_num?: string;
    cc_email?: string;
  };
  operatingArea: {
    op_area?: number;
    lat: number;
    lon: number;
  };
  tracking: {
    jobrefresh?: number;
    trackrefresh?: number;
  };
  host: {
    coreLib?: string;
    integraLib?: string;
  };
  refs: any[];
  tariffs: WebTariff[];
  goodsTypes: GoodsType[];
};

export type IntegraProfile = {
  reference: number;
  loggedIn: number;
  accounts: Account[];
  credentialSession: any;
  userSession: SessionAuth;
  user: IntegraUser;
  settings: any;
  loqate?: LoqateApiConfig;
  what3words?: W3WApiConfig;
};

type ApiDetails = {
  domain: string;
  key: string;
  countries: string;
  countriesDescription: string;
};

type LoqateApiConfig = ApiDetails & {
  account?: string;
};

type W3WApiConfig = ApiDetails;

export type Account = {
  contactRef?: number;
  accountRef: string;
  description: string;
  email?: string;
  signUp: {
    signUpByPin: number;
    signUpByCode: number;
    signUpByDomain: number;
  };
};

export type ReportRunRequest = {
  reportRef: number;
  filters: ReportFilterValue[];
};

export type ReportFilterValue = {
  ident: string;
  value: string;
};

export type ReportHeader = {
  reference: number;
  account_ref: string;
  contact_ref: number;
  description: string;
  noupdate: string;
  report_type: number;
  html_path: string;
};

export type ReportColumn = {
  reference: number;
  report_ref: number;
  fieldposn: number;
  field: string;
  formatting: string;
  heading: string;
};

export type ReportFilter = {
  reference: number;
  report_ref: number;
  field: string;
  criteria: string;
  compvalue: string;
  compValueDataType?: string;
  fieldposn: number;
  prompt: string;
  compulsory: number;
  valueIdent: string;
  heading: string;
};

export type GenericObject = { [key: string]: any };

export type ReportListResponse = ReportHeader[];

export type ReportRunResponse = {
  reportHeader: ReportHeader;
  reportColumns: ReportColumn[];
  reportFilters: ReportFilter[];
  reportData: GenericObject[];
};

export type ContactCardAddRequest = {
  contactRef: number;
  default?: boolean;
  nameOnCard?: string;
  card: CardTokenInfo;
};

export type ContactCardAddResponse = Response & {
  reference: number;
};

export type ContactCardDeleteRequest = {
  contactRef?: number;
  reference: number;
};

export type ContactCardMakeDefaultRequest = {
  contactRef?: number;
  reference: number;
};

export type CancelBookingRequest = {
  ref: number;
  reason?: string;
};

export type CancelBookingResponse = {
  ref: number;
  result: string;
};

export type Address = BookingAddress & {
  code?: string;
  country?: string;
  countryCode?: string;
  notes?: string;
};

export type ContactAddressAddRequest = {
  contactRef: number;
  default?: boolean;
  addr: Address;
};

export type ContactAddressAddResponse = {
  reference: number;
};

export type ContactAddressDeleteRequest = {
  contactRef?: number;
  reference: number;
};

export type JourneyMakeFavouriteRequest = {
  reference: number;
  description?: string;
};

export type JourneyClearFavouriteRequest = {
  reference: number;
};

export type ContactAddressMakeDefaultRequest = {
  contactRef?: number;
  reference: number;
};

export type ContactAddressClearDefaultRequest = {
  contactRef?: number;
  reference: number;
};

export type ContactAddressDeleteResponse = SuccessResponse;

export type JourneyMakeFavouriteResponse = SuccessResponse;

export type JourneyClearFavouriteResponse = SuccessResponse;

export type ContactAddressMakeDefaultResponse = SuccessResponse;

export type ContactAddressClearDefaultResponse = SuccessResponse;

export type ContactAddressEditRequest = {
  contactRef: number;
  default?: boolean;
  addr: Partial<Address> & { ref: number };
};

export type ContactAddressEditResponse = SuccessResponse;

export type FlightLookupRequest = {
  flightNumber: string;
  flightDt?: string;
  departure?: boolean;
};

export type FlightResponse = {
  flightNumber: number;
  carrierCode: string;
  departTerminal: string;
  arriveTerminal: string;
  localDepartTime: string;
  localArriveTime: string;
  localDepartDate: string;
  localArriveDate: string;
  localDepartDateDesc: string;
  localArriveDateDesc: string;
  departAirportDesc: string;
  arriveAirportDesc: string;
  departAirportCode: string;
  arriveAirportCode: string;
  departAddressRef: number;
  arriveAddressRef: number;
};

export type FlightLookupResponse = {
  flightSummary: string;
  flights: FlightResponse[];
};

export type Stop = {
  address: AddressWithType;
  flight?: FlightDetail;
};

export type FlightDetail = {
  code: string;
  fromToCity: string;
  dateTime: string;
  terminal: string;
  departure: boolean;
  number: number;
  carrierCode: string;
  scheduledDepartureDt: string;
  scheduledArrivalDt: string;
  departAirportCode: string;
  arriveAirportCode: string;
  holdOff: number;
};

export type JobContact = {
  ref: number;
  name?: string;
  phone?: string;
  mobile?: string;
  email?: string;
};

export type FieldDisplay = {
  label: string;
  value: string;
};

export type BookingDisplay = {
  reference: number;
  contactRef: number;
  subtitle: string;
  fields: FieldDisplay[];
};

export type ContactLinkReqRequest = {
  contactRef?: number;
  contactEmail: string;
};

export type ContactLinkReqResponse = SuccessResponse;

export type ContactLinkListRequest = {
  contactRef?: number;
};

export type ContactLink = {
  contactRef: number;
  code?: string;
  name?: string;
  tel?: string;
  mob?: string;
  fax?: string;
  email?: string;
};

export type PendingContactLink = ContactLink & {
  accountRef: string;
  authGuid: string;
};

export type ContactLinkPendingListResponse = Response & {
  contacts: PendingContactLink[];
};

export type ContactLinkListResponse = Response & {
  contacts: ContactLink[];
};

export type ContactLinkAuthoriseRequest = {
  contactRef?: number;
  contactEmail?: string;
  authGuid: string;
  authorise: boolean;
};

export type ContactLinkAuthoriseResponse = SuccessResponse;

export type SendConfirmationRequest = {
  jobRef: number;
  email: string;
};

export type SendConfirmationResponse = Response & {
  code: number;
  description: string;
};

export type Populate3waDetailsRequest = {
  words: string;
};

export type Populate3waDetailsResponse = Response & {
  addressName: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  postcode: string;
  country?: string;
  countryCode?: string;
  lat: number;
  long: number;
  typeCode: string;
};

export type SwitchToBrowserResponse = Response & {
  result: string;
  err?: string;
  param1?: string;
  param2?: string;
};
