import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { IonApp, IonRouterOutlet } from '@ionic/vue';
import GlobalMenu from './components/GlobalMenu.vue';
import CookieConsentModal from './components/CookieConsentModal.vue';
import { useStore } from './store';
import { onMounted, ref } from 'vue';
import { isWebx } from './utils/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const store = useStore();
const showCookieModal = ref(false);

// Check for cookie consent when the app is launched
onMounted(() => {
  if (!store.cookieConsent && isWebx()) {
    showCookieModal.value = true;
  }
});

const acceptCookies = () => {
  store.cookieConsent = true;
  showCookieModal.value = false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(GlobalMenu),
      _createVNode(_unref(IonRouterOutlet)),
      _createVNode(CookieConsentModal, {
        isOpen: showCookieModal.value,
        onAccept: acceptCookies
      }, null, 8, ["isOpen"])
    ]),
    _: 1
  }))
}
}

})